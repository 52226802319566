<template>
  <div class="full-screen ltr-type">
    <div class="tv zone1">Zone 1</div>
  </div>
</template>

<script>

export default {
  name: 'Tv3'
}
</script>

<style lang="scss" scoped>
    .zone1 {
        width: 100%;
        height: 100%;
        background-color: #fcb340;
    }
</style>